<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('SERVICES_LIST.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'servicesListIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-1" :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-2" :label="`${$t('FORM.CATEGORY')}`" label-for="select-1" label-cols-md="2" label-align-md="right">
              <SelectInfinityScroll
                id="select-1"
                v-model="$v.form.category.$model"
                :search.sync="searchCategory"
                :disabled="!form.category"
                :state="validateState('category') === false"
                api="company/service-categories"
                clearable
                @input="clearServerError('category')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('category') === false}">
                <template v-if="serverErrors.category">{{ serverErrors.category[0] }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="8" class="d-flex align-items-center">
                <div class="h5">{{ $t('SERVICES_LIST.DISCOUNTS') }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="8" offset-md="2">
                <b-form-group
                  id="input-group-3" :label="`${$t('SERVICES_LIST.PERIODICITY')}`" label-for="input-2" label-cols-md="2" label-align-md="right"
                >
                  <b-input-group>
                    <InputForm
                      id="input-2"
                      v-model="$v.discount.periodicity.$model"
                      :state="validateDiscountState('periodicity')"
                      :placeholder="$t('PLACEHOLDER.PERIODICITY')"
                      trim
                      @input="clearServerError('periodicity', 'discount')"
                      aria-describedby="input-2-feedback"
                    />

                    <template #append>
                      <b-input-group-text class="border-0"><strong class="text-black-50">{{ $t('SERVICES_LIST.ITEM') }}</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <b-form-invalid-feedback id="input-1-feedback">
                    <template v-if="serverErrors.periodicity">{{ serverErrors.periodicity[0] }}</template>
                    <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('SERVICES_LIST.PERIODICITY') }) }}</template>
                  </b-form-invalid-feedback>
                  <span class="form-text text-muted">{{ $t('SERVICES_LIST.DISCOUNT_HINT') }}</span>
                </b-form-group>

                <b-form-group id="input-group-4" :label="`${$t('SERVICES_LIST.DISCOUNT_IS')}`" label-cols-md="2" label-align-md="right">
                  <b-input-group>
                    <InputForm
                      id="input-1"
                      v-model="$v.discount.percent.$model"
                      :state="validateDiscountState('percent')"
                      :placeholder="$t('PLACEHOLDER.DISCOUNT')"
                      trim
                      @input="clearServerError('percent', 'discount')"
                      aria-describedby="input-1-feedback"
                    />

                    <template #append>
                      <b-input-group-text class="border-0"><strong class="text-black-50">%</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <b-form-invalid-feedback id="input-1-feedback">
                    <template v-if="serverErrors.percent">{{ serverErrors.percent[0] }}</template>
                    <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('SERVICES_LIST.DISCOUNT_IS') }) }}</template>
                  </b-form-invalid-feedback>
                  <span class="form-text text-muted">{{ $t('SERVICES_LIST.PERCENT_HINT') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="8" class="d-flex align-items-center">
                <div class="h5">{{ $t('SERVICES_LIST.TABLE_PRICE') }}</div>
              </b-col>
              <b-col cols="4" class="d-flex align-items-center justify-content-end">
                <div class="btn btn-icon btn-lg btn-dropdown p-0" @click="active = !active">
                  <span class="svg-icon svg-icon-xl svg-icon-success">
                    <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
                  </span>
                </div>
              </b-col>
              <b-col cols="12">
                <Table per-page="0" :fields="fields" :items="items" :busy.sync="loading">
                  <template #cell(cost)="data">
                    {{ data.item.cost | centsToDollars }}
                  </template>
                  <template #cell(startDate)="data">
                    {{ $moment(data.item.startDate).format(FORMAT_DATE) }}
                  </template>
                  <template #cell(endDate)="data">
                    {{ $moment(data.item.endDate).format(FORMAT_DATE) }}
                  </template>
                  <template #cell(actions)="data">
                    <div style="min-width: 80px">
                      <div class="btn btn-icon btn-light-info btn-sm mr-3" @click="itemEdit = data.item; active = true">
                        <span class="svg-icon btn-light-primary">
                          <inline-svg src="/media/svg/icons/Communication/Write.svg" />
                        </span>
                      </div>
                      <div class="btn btn-icon btn-light-danger btn-sm" @click="onDelete(data.item)">
                        <span class="svg-icon svg-icon-md btn-light-info">
                          <inline-svg src="/media/svg/icons/Home/Trash.svg" />
                        </span>
                      </div>
                    </div>
                  </template>
                </Table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="discount.uuid"
                :disabled="loading" variant="secondary" size="sm" class="mr-4" @click="onRemoveDiscount"
              >
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('SERVICES_LIST.REMOVE_DISCOUNT') }}
              </b-button>

              <b-button :disabled="loading || $v.form.$anyError || $v.discount.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <quick-panel v-model="active">
      <template #title>{{ $t('SERVICES_LIST.FORM_PRICE_TITLE') }}</template>
      <template #body>
        <ServicePriceForm v-if="active" :service="$route.params.id" :itemEdit="itemEdit" @onAdd="onAdd" @onEdit="onEdit" />
      </template>
    </quick-panel>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric, maxValue } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/servicesRepository'
import RepoPrice from '@/core/repository/company/servicePricesRepository'
import RepoDiscount from '@/core/repository/company/serviceDiscountRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../../content/Card'
import InputForm from '../../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../../../../../components/forms-items/SelectInfinityScroll'
import ServicePriceForm from '../../../../../../components/company/services/ServicePriceForm'
import ModalConfirm from '../../../../../../components/modals/ModalConfirm'
import Table from '../../../../../../components/UI/Table'
import { FORMAT_DATE } from '../../../../../../helpers/validation'

export default {
  name: 'ServicesListEdit',
  components: {
    Table,
    SelectInfinityScroll,
    InputForm,
    KTCard,
    ServicePriceForm, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      FORMAT_DATE,
      active: false,
      itemEdit: null,
      loading: false,
      fields: [
        {
          key: 'cost',
          label: this.$t('SERVICES_LIST.COST'),
          sortable: false,
        },
        {
          key: 'startDate',
          label: this.$t('SERVICES_LIST.START_DATE'),
          sortable: false,
        },
        {
          key: 'endDate',
          label: this.$t('SERVICES_LIST.END_DATE'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
      items: [],
      form: {
        name: '',
        category: null,
      },
      discount: {
        uuid: '',
        service: this.$route.params.id,
        percent: '',
        periodicity: '',
      },
      params: {
        pagination: {
          page: 1,
          limit: 100,
        },
        filter: {
          service: this.$route.params.id,
        },
      },
      searchCategory: '',
      validations: {
        form: {
          name: { required },
          category: {},
        },
        discount: {
          percent: {
            numeric,
            maxValue: maxValue(100),
          },
          periodicity: { numeric },
        },
      },
    }
  },
  watch: {
    active(val) {
      if (!val) this.itemEdit = null
    },
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('SERVICES_LIST.TITLE_EDIT') }])
  },
  methods: {
    onEdit(res) {
      const index = this.items.findIndex((f) => f.uuid === this.itemEdit.uuid)
      if (index >= 0) {
        this.$set(this.items, index, res)
      }
      this.active = false
    },
    onDelete(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            RepoPrice.delete(item.uuid).then(() => {
              const index = this.items.findIndex((f) => f.uuid === item.uuid)
              if (index >= 0) {
                this.items.splice(index, 1)
              }
            })
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false, 
      })
    },
    onAdd(res) {
      this.items.push(res)
      this.active = false
    },
    async fetchData() {
      this.loading = true
      await RepoPrice.list(this.params).then(({ data }) => {
        this.items = data.payload.items
      })
      await Repo.get(this.$route.params.id).then(({ data }) => {
        this.setItem(data.payload)
      })
      await RepoDiscount.list({
        services: [this.$route.params.id],
      }).then(({ data }) => {
        const discount = data.payload.items.length ? data.payload.items[0] : null
        if (discount) {
          this.discount = {
            service: this.$route.params.uuid,
            uuid: discount.uuid,
            percent: discount.percent,
            periodicity: discount.periodicity,
          }
        }
        this.loading = false
      })
    },
    setItem(item, key = 'form') {
      Object.keys(this[key]).forEach((propName) => {
        if (
          this[key].hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this[key][propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    validateDiscountState(name) {
      const { $dirty, $error } = this.$v.discount[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      obj.category = obj.category ? obj.category.uuid : obj.category

      Repo.patch(this.$route.params.id, obj).then(async () => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        await this.submitDiscount()
        setTimeout(() => this.$router.push({ name: 'servicesListIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },

    async submitDiscount() {
      this.$v.discount.$touch()
      if (this.$v.discount.$invalid) {
        return
      }
      this.clearServerErrors('discount')
      const { uuid, ...data } = this.discount

      if (this.discount.uuid) {
        await RepoDiscount.patch(uuid, data).then(() => {
          this.$bvToast.toast(this.$t('TOAST.EDITED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data, 'discount')
        })
      } else {
        await RepoDiscount.post(data).then(() => {
          this.$bvToast.toast(this.$t('TOAST.CREATED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data, 'discount')
        })
      }
    },

    onRemoveDiscount() {
      if (this.discount.uuid) {
        RepoDiscount.delete(this.discount.uuid).then(() => {
          this.resetDiscountForm()
          this.$bvToast.toast(this.$t('TOAST.REMOVED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
        })
      }
    },

    resetDiscountForm() {
      this.discount = {
        uuid: '',
        service: this.$route.params.id,
        percent: '',
        periodicity: '',
      }
    },
  },
}
</script>

<style scoped>

</style>
